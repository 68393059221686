import axios from 'axios'
import config from '../config'

import * as Sentry from '@sentry/browser'

const getInsights = atcCode => {
  return new Promise((resolve, reject) => {
    return axios
      .get(config.INSIGHTS_BASE_URL + '/insights/effects/atc/' + atcCode, {
        headers: _getRequestHeaders()
      })
      .then(response => {
        if (response.data) {
          resolve(response.data)
        }
      })
      .catch(error => {
        console.error(error)
        Sentry.captureException(error)
        reject(error)
      })
  })
}

const _getRequestHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    'X-API-Version': '3.0.0',
    'X-Event-Version': '3.0.0',
    'X-Event-App-Created': 'med-app-insights',
    'X-Event-Role': 'patient'
  }
  return headers
}

export { getInsights }
