import React from 'react'

import Chart from '../../components/Chart'

function Home () {
  return (
    <div>
      <Chart />
    </div>
  )
}

export default Home
