export default {
  INSIGHTS_BASE_URL: process.env.REACT_APP_INSIGHTS_BASE_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,
  CHART_MINIUM: 5,
  CHART_LOWER_MAX: 10,
  CHART_MORE_THRESHOLD: 12,
  CHART_HIGHER_MAX: 25,
  NODE_ENV: process.env.NODE_ENV
}
