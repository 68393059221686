import React from 'react'

import ErrorBoundary from 'components/ErrorBoundary'
import Home from 'containers/Home'

function App () {
  return (
    <ErrorBoundary>
      <Home path='/' />
    </ErrorBoundary>
  )
}

export default App
