import React from 'react'
import I18n from 'i18n'
import * as Sentry from '@sentry/browser'

import { makeStyles, withTheme } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import MedTooltip from '@bit/medikura.med-lib-uno.med-tooltip'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedBarChart from '@bit/medikura.med-lib-uno.med-bar-chart'

import config from 'config'
import { getInsights } from 'service'
import { getStringParamFromURL } from 'utils/urlParser'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.border.dark,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      border: 'none'
    }
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  headline: {
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  infoIcon: {
    cursor: 'help',
    fontSize: '0.8em',
    marginLeft: theme.spacing(1)
  },
  horizontalWrapper: {
    display: 'flex'
  }
}))

const Chart = ({ theme }) => {
  const classes = useStyles()

  const [sideEffectsList, setSideEffectsList] = React.useState([])
  const [isExtended, setExtended] = React.useState(false)

  React.useEffect(() => {
    let atcCode = null
    const rootElement = document.getElementById('medikuraInsightsApp')

    if (rootElement.dataset && rootElement.dataset.atc) {
      atcCode = rootElement.dataset.atc
    } else {
      atcCode = getStringParamFromURL(window.location.search, 'atc')
    }

    if (!atcCode) {
      console.error('No atc code provided')
      return
    }

    getInsights(atcCode)
      .then(response => {
        if (response.sideEffects) {
          setSideEffectsList(response.sideEffects)
        }
      })
      .catch(error => {
        console.error(error)
        Sentry.captureException(error)
      })
  }, [])

  if (sideEffectsList.length < config.CHART_MINIUM) {
    return null
  }

  const chartData = {}
  for (const sideEffect of sideEffectsList) {
    chartData[sideEffect.name] = sideEffect.percentage
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <MedTypography variant='h2' className={classes.headline}>
          {I18n.t('chart.title')}
          <MedTooltip title={I18n.t('chart.titleTooltip')}>
            <span className={classes.infoIcon}>ⓘ</span>
          </MedTooltip>
        </MedTypography>
        {sideEffectsList.length >= config.CHART_MORE_THRESHOLD && (
          <Link
            href='#'
            onClick={e => {
              e.preventDefault()
              setExtended(!isExtended)
            }}
          >
            {isExtended
              ? I18n.t('chart.showLess') + ' »'
              : I18n.t('chart.showMore') + ' »'}
          </Link>
        )}
      </div>
      <MedBarChart
        color={theme.palette.primary.main}
        data={chartData}
        limit={isExtended ? config.CHART_LOWER_MAX : config.CHART_HIGHER_MAX}
        legendLabel={I18n.t('chart.legend')}
        postfix='%'
      />
    </div>
  )
}

export default withTheme(Chart)
