import queryString from 'query-string'

const getBooleanParamFromURL = (url, queryParameter) => {
  const parsed = queryString.parse(url)
  return parsed[queryParameter] ? parsed[queryParameter] === 'true' : false
}

const getStringParamFromURL = (url, queryParameter, fallback = '') => {
  const parsed = queryString.parse(url)
  return parsed[queryParameter] ? parsed[queryParameter] : fallback
}

export { getStringParamFromURL, getBooleanParamFromURL }
